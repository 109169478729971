import { Controller } from "@hotwired/stimulus"
import Swiper, { Navigation, Pagination } from 'swiper'
Swiper.use([Navigation, Pagination])

export default class extends Controller {
  static targets = ['']

  connect() {
    this.swiper = new Swiper(this.element, {
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    })
  }
}
