import { Controller } from "@hotwired/stimulus"

import Velocity from "velocity-animate"

export default class extends Controller {
  static targets = [ "subnav", "overlay" ]

  toggle_subnav() {
    var subnav = this.subnavTarget
    var menu = subnav.querySelector('.subnav-menu')
    if (subnav.classList.contains('is-active')) {
      var slideDir = 'slideUp'
      subnav.classList.remove('is-active')
    } else {
      var slideDir = 'slideDown'
      subnav.classList.add('is-active')
    }

    Velocity(menu, slideDir, {duration: 200, easing: 'ease-in-out' })

    event.preventDefault()
  }

  openOverlay() {
    this.overlayTarget.style.height = "100%"
  }

  closeOverlay() {
    this.overlayTarget.style.height = "0%"
  }

  teardown() {
    this.closeOverlay()
  }
}
