import { Controller } from "@hotwired/stimulus"

import Popper from 'popper.js'

export default class extends Controller {
  static targets = [ "button", "popover" ]

  connect() {
  }

  open() {
    event.preventDefault()

    var reference = this.buttonTarget
    var popover = this.popoverTarget

    if (popover.style.display == 'block') {
      // Hide current popover
      // and do nothing else
      this.close()
    } else {
      // Hide current popover
      this.close()

      // Show new popover
      var popper = new Popper(reference, popover, {
        placement: 'bottom'
      })
      popover.style.display = 'block'
      popper.scheduleUpdate()

      // Set current popover target
      popover.dataset.target += ' current-popper.popover'
    }

  }

  close() {
    let currentPopperController = this.application.getControllerForElementAndIdentifier(
      document.querySelector('body'),
      "current-popper"
    )
    currentPopperController.close()
  }
}
