import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    if (!navigator.share && this.data.get('hide')) {
      this.element.remove()
    }
  }

  share() {
    if (navigator.share) {
      navigator.share({
        title: this.data.get('title'),
        text: this.data.get('text'),
        url: this.data.get('url')
      })
    } else {
      Turbo.visit(this.data.get('url'))
    }
  }
}
