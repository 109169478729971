import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['expandable', 'collapsible']

  connect() {
    if (this.data.get('expanded') == null) this.data.set('expanded', false)

    let disabled = this.data.get('expanded') == 'false'
    this.disableInputs(this.expandableTarget, disabled)
    if (this.hasCollapsibleTarget) {
      this.disableInputs(this.collapsibleTarget, !disabled)
    }
  }

  expand() {
    event.preventDefault()
    this.data.set('expanded', true)

    this.disableInputs(this.expandableTarget, false)
    if (this.hasCollapsibleTarget) {
      this.disableInputs(this.collapsibleTarget, true)
    }
  }

  collapse() {
    event.preventDefault()
    this.data.set('expanded', false)

    this.disableInputs(this.expandableTarget, true)
    if (this.hasCollapsibleTarget) {
      this.disableInputs(this.collapsibleTarget, false)
    }
  }

  toggle() {
    event.preventDefault()

    if (this.data.get('expanded') == 'true') {
      this.collapse()
    } else {
      this.expand()
    }
  }

  disableInputs(target, disabled) {
    target.querySelectorAll('select, input, textarea').forEach(function(item, i){ item.disabled = disabled; });
  }
}
