import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['dialog', 'background']

  connect() {
  }

  setContent(id) {
    this.dialogTarget.innerHTML = document.querySelector(`[data-modal='${id}']`).innerHTML
  }

  open() {
    this.dialogTarget.classList.remove('is-hidden')
    this.backgroundTarget.classList.remove('is-hidden')
  }

  close(event) {
    if (event.keyCode == 27 || event.type == 'click') {
      this.dialogTarget.classList.add('is-hidden')
      this.backgroundTarget.classList.add('is-hidden')
      this.dialogTarget.innerHTML = ''
    }
  }
}
