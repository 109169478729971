import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['wrapper', 'startDate', 'startTime', 'endDate', 'endTime', 'endDatePlaceholder']

  connect() {
    console.log('test')
  }

  showEndDate() {
    event.preventDefault()
    this.wrapperTarget.dataset.endDate = true
    this.endDateTarget.closest('.field').classList.remove('is-hidden')
    this.endDateTarget.value = this.startDateTarget.value
    this.endDatePlaceholderTarget.disabled = true
    event.currentTarget.remove()
  }
}
