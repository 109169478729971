// https://github.com/Vizzuality/laws_and_pathways/pull/245/files
// https://github.com/asifaly/tradoc/commit/08145a2dee8f40bb08893061779e8e334f42ceac#diff-b3a5e890d93fa4cb2cff5c9e45336c4b
// https://stackoverflow.com/questions/61867995/how-to-embed-an-iframe-with-actiontext-trix-on-ruby-on-rails
import { Controller } from '@hotwired/stimulus'

const Trix = require('trix')
import Rails from '@rails/ujs'

export default class extends Controller {

  connect() {
    // const embedIcon = `
    //   <button type="button" class="trix-button trix-button--icon trix-button--icon-video"
    //     data-trix-attribute="embed" title="Embed Video" tabindex="-1">Video</button>`;
    // const embedDialog = `
    //   <div class="trix-dialog trix-dialog--video" data-trix-dialog="embed" data-trix-dialog-attribute="embed">
    //      <div class="trix-dialog__link-fields">
    //       <input type="url" name="embed" class="trix-input trix-input--dialog" placeholder="Enter a Youtube URL…" aria-label="URL" required="" data-trix-input disabled="disabled" data-trix-action='x-test'>
    //       <div class="trix-button-group">
    //         <input type="button" class="trix-button trix-button--dialog" value="Insert" data-trix-dialog-submit--video>
    //         <input type="button" class="trix-button trix-button--dialog" value="Cancel" data-trix-method="removeAttribute">
    //       </div>
    //     </div>
    //   </div>`;

    const embedDialog = `
      <div data-behaviour="embed-container">
        <div class="embed-loading-indicator">
          Inhalt wird geladen...
        </div>
        <div class="embed-url-wrapper">
          <span>Möchtest du den Inhalt dieser Seite einbinden?</span>
          <input class="button is-small" type="button" data-behaviour="embed-url" value="Ja, einbinden">
        </div>
      </div>`

    const toolbar = this.element.toolbarElement;
    toolbar.querySelector('[data-trix-dialog=href]').insertAdjacentHTML('beforeend', embedDialog)

    this.hrefInput = toolbar.querySelector("[data-trix-dialog=href] [name='href']")
    this.embedContainer = toolbar.querySelector("[data-behaviour='embed-container']")
    this.embedButton = toolbar.querySelector('[data-behaviour="embed-url"]')
    this.embedLoadingIndicator = this.embedContainer.querySelector('.embed-loading-indicator')
    this.embedUrlWrapper = this.embedContainer.querySelector('.embed-url-wrapper')

    this.hrefInput.autocomplete = 'off'

    this.hrefInput.addEventListener("input", this.didInput.bind(this))
    this.hrefInput.addEventListener("focusin", this.didInput.bind(this))
    this.embedButton.addEventListener('click', this.embed.bind(this))
  }

  didInput(event) {
    let value = event.target.value.trim()

    // Load patterns from server so we can dynamically update them
    if (this.patterns === undefined) {
      this.loadPatterns(value)

      // When patterns are loaded, we can just fetch the embed code
    } else if (this.match(value)) {
      this.fetch(value)

      // No embed code, just reset the form
    } else {
      this.reset()
    }
  }

  loadPatterns(value) {
    Rails.ajax({
      type: "get",
      url: "/embeds/patterns.json",
      success: (response) => {
        this.patterns = response.map(pattern => new RegExp(pattern.source, pattern.options))
        if (this.match(value)) {
          this.fetch(value)
        }
      }
    })
  }

  // Checks if a url matches an embed code format
  match(value) {
    return this.patterns.some(regex => regex.test(value))
  }

  fetch(value) {
    this.embedUrlWrapper.style.display = 'none'
    this.embedLoadingIndicator.style.display = 'flex'

    Rails.ajax({
      url: `/embeds?id=${encodeURIComponent(value)}`,
      type: 'post',
      error: this.reset.bind(this),
      success: this.showEmbed.bind(this)
    })
  }

  embed(event) {
    if (this.currentEmbed == null) { return }

    let attachment = new Trix.Attachment(this.currentEmbed)
    this.element.editor.insertAttachment(attachment)
    this.element.focus()
    this.hrefInput.value = ''
    this.reset()
  }

  showEmbed(embed) {
    this.currentEmbed = embed
    this.embedUrlWrapper.style.display = "flex"
    this.embedLoadingIndicator.style.display = "none"
  }

  reset() {
    this.embedUrlWrapper.style.display = "none"
    this.embedLoadingIndicator.style.display = "none"
    this.currentEmbed = null
  }
}
