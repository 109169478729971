import { Controller } from "@hotwired/stimulus"

import Velocity from 'velocity-animate'

// REQUIRES VELOCITY ANIMATE
export default class extends Controller {
  static targets = ['header', 'body']

  connect() {
  }

  toggle(event) {
    var matches = function(el, selector) {
      return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector)
    }

    if (!matches(event.target, 'a, a *, button, button *')) {
      event.preventDefault()

      if (this.bodyTarget.style['display'] != 'none') {
        var slideDir = 'slideUp'
        this.headerTarget.classList.remove("is-expanded")
      } else {
        var slideDir = 'slideDown'
        this.headerTarget.classList.add("is-expanded")
      }
      Velocity(this.bodyTarget, slideDir, {duration: 400, easing: 'ease-in-out' })
    }
  }
}
