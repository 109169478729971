import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'input', 'label' ]

  update() {
    if (this.inputTarget.files.length > 0) {
      this.labelTarget.innerHTML = this.inputTarget.files[0].name
    }
  }

}
