import { Controller } from "@hotwired/stimulus"
const flatpickr = require("flatpickr");

export default class extends Controller {
  static targets = [ "date", "time" ]

  connect() {
    if (this.hasDateTarget) {
      this.datePicker = flatpickr(this.dateTarget, {
        dateFormat: "d.m.Y"
      });
    }
    if (this.hasTimeTarget) {
      this.timePicker = flatpickr(this.timeTarget, {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
      });
    }
  }

  disconnect() {
    if (this.hasDateTarget) {
      this.datePicker.destroy()
    }
    if (this.hasTimeTarget) {
      this.timePicker.destroy()
    }
  }

  clearDate() {
    this.datePicker.clear()
  }

  clearTime() {
    this.timePicker.clear()
  }
}
